import React from 'react'
import SideMenuComponent from './components/SideMenuComponent'
import HeaderComponent from './components/HeaderComponent'
import { Box } from '@mui/system'
import { Toolbar } from '@mui/material'
import { useMenuContext } from '../providers/MenuProvider'

const Layout = ({ children }) => {
    const { contentML } = useMenuContext()

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden'
            }}>
                <HeaderComponent />
                <SideMenuComponent />
                <main>
                    <Toolbar />
                    <Box sx={{
                        // width: contentVW,
                        ml: contentML
                    }}>
                        {children}
                    </Box>
                </main>

            </Box>
        </>
    )
}

export default Layout

