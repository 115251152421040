import { Box, Button } from '@mui/material';
import React from 'react';
import Layout from '../../layout/Layout';
import { useMenuContext } from '../../providers/MenuProvider';
import { useStatsContext } from '../../providers/StatsProvider';
import StatsTable from './components/StatsTable';

const StatsPage = () => {

    const { contentML } = useMenuContext()

    const { clearStats } = useStatsContext()

    return (
        <Layout>
            <Box sx={{
                position: 'absolute',
                top: '60px',
                left: contentML,
                right: '0',
                bottom: '0',
                overflowX: 'none'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    p: '10px'
                }}>
                    <Button onClick={clearStats} variant="contained">clear</Button>
                </Box>
                <StatsTable />

            </Box>
        </Layout>
    );
};

export default StatsPage;
