import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Toolbar, Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { drawerWidth } from '../../defs';
import { useMenuContext } from '../../providers/MenuProvider';
import { useNavigate } from 'react-router-dom';


const SideMenuComponent = () => {
    const { isOpen, handleMenuOpen, menuData, updateHeaderLabel, additionalMenuData } = useMenuContext()
    const navigate = useNavigate();

    const onMenuItemSelect = (item) => {
        updateHeaderLabel(item.label);
        navigate(item.path)
    }

    return (
        <Drawer variant="permanent" open={isOpen}
            sx={{
                position: 'fixed',
                zIndex: '999'
            }}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={handleMenuOpen}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List>
                <Box>
                    {menuData.map((item) => (
                        <ListItem key={item.id} button onClick={() => onMenuItemSelect(item)}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </Box>
            </List>
            <Divider />
            <List>
                <Box>
                    <ListSubheader inset>Additional section</ListSubheader>
                    {
                        additionalMenuData.map((item) => (
                            <ListItem key={item.id} button>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItem>
                        ))
                    }
                </Box>
            </List>
        </Drawer>
    )
}

export default SideMenuComponent

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
            overflowX: 'hidden'
        },
    }),
);