import { DataGrid } from '@mui/x-data-grid'
import React, { useMemo } from 'react'
import { MaxStatsItemsPerPage } from '../../../defs';
import { useStatsContext } from '../../../providers/StatsProvider';

const StatsTable = () => {

    const { stats, page, rowCount, setPage, switchToPage } = useStatsContext()

    const columns = useMemo(
        () => [
            {
                field: 'id', headerName: "ID", type: 'number', flex: 1,
                renderCell: (params) => {
                    return (params.value.toString().padStart(6, '0'));
                },
            },
            { field: 'productId', headerName: "Id продукта", type: 'number', flex: 1 },
            { field: 'productName', headerName: "Название продукта", type: 'string', flex: 6 },
            { field: 'actionType', headerName: "Тип действия", type: 'string', flex: 3 },
            { field: 'actionTime', headerName: "Время действия", type: 'dateTime', flex: 3 },
        ], []
    );


    const onDataGridPageChange = (newPage) => {
        setPage(newPage)
        switchToPage(newPage)
    }


    return (
        <div style={{ height: '90%', width: '100%' }}>
            <DataGrid
                hideFooterSelectedRowCount
                paginationMode="server"
                columns={columns}
                rows={stats}

                page={page}
                rowCount={rowCount}
                pageSize={MaxStatsItemsPerPage}
                rowsPerPageOptions={[MaxStatsItemsPerPage]}
                onPageChange={onDataGridPageChange}
            // onPageSizeChange={(pageSize) => { console.log("pageSize: " + pageSize) }}
            />
        </div>
    )
}

export default StatsTable