import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import PromptingRemoveDialog from '../../../dialogs/PromptingRemoveDialog';
import { getCorrectedDigits } from '../../../utils';


const ShippingSettingsItem = ({ item, shippingPriceChangedCallback, removeItemCallback }) => {
    const [open, setOpen] = useState(false)
    const [shippingPrice, setShippingPrice] = useState(item.price)

    const closeDialogHandler = (e) => {
        setOpen(false)
    }

    const agreeButoonHandler = (e) => {
        removeItemCallback(item)
    }

    const removeItemHandler = (e) => {
        e.stopPropagation()

        setOpen(true)
    }

    return (
        <>
            <Accordion >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton onClick={removeItemHandler} >
                            <CancelIcon />
                        </IconButton>
                        <Typography>{item.label}</Typography>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        m: '0px 15px 10px 15px'
                    }}>
                        <Typography>Цена доставки: </Typography>
                        <TextField
                            required
                            placeholder='%'
                            sx={{ input: { textAlign: "right" } }}
                            value={shippingPrice}
                            onChange={(e) => {
                                item.price = getCorrectedDigits(e.target.value)

                                setShippingPrice(item.price)
                                shippingPriceChangedCallback(item)
                            }} />
                    </Box>


                </AccordionDetails>

            </Accordion >
            <PromptingRemoveDialog open={open} closeDialogHandler={closeDialogHandler} agreeButoonHandler={agreeButoonHandler} />
        </>
    )
}

export default ShippingSettingsItem