import { Box, Button, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useCategoriesContext } from '../../../providers/web_layout/CategoriesSettingsProvider';
import { useMenuContext } from '../../../providers/MenuProvider';
import { useLayoutContext } from '../../../providers/web_layout/WebLayoutProvider';
import CategoriesSettingsItem from './CategoriesSettingsItem';
import { CategoriesEditorState } from '../../../defs';
import AddEditCatagoryDialog from '../../../dialogs/AddEditCatagoryDialog';

const CategoriesSettings = () => {
    const { contentML } = useMenuContext()
    const { layout, saveLayoutChanges } = useLayoutContext()
    const {
        resetValues,
        initValues,
        addCategory,
        updateCategory,
        removeCategory,
    } = useCategoriesContext()

    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [dialogState, setDialogState] = useState(CategoriesEditorState.ADD)


    const addButtonHandler = (e) => {
        resetValues()
        setDialogState(CategoriesEditorState.ADD)
        setOpen(true)
    }

    const editButtonHandler = (item) => {
        setSelectedItem(item);

        initValues(item)
        setDialogState(CategoriesEditorState.EDIT)
        setOpen(true)
    }

    const closeDialogHandler = (e) => {
        setOpen(false)
    }

    const addDialogButtonHandler = (e) => {
        setOpen(false)
        addCategory(layout)
    }

    const editDialogButtonHandler = (e) => {
        setOpen(false)

        updateCategory(layout, selectedItem)
    }

    const removeItemHandler = (item) => {
        removeCategory(layout, item)
    }

    const discountChangedHandler = (item) => {
        const index = layout.categories.categories_data.findIndex((data) => data.id === item.id)
        layout.categories.categories_data[index] = item;
    }

    const saveButtonHandler = (e) => {
        saveLayoutChanges(layout)
    }

    return (
        <>
            {
                layout !== null ? (
                    <>
                        <Box sx={{
                            m: '10px 20px 80px 20px'
                        }}>
                            {
                                layout.categories.categories_data.map((item, index) => (
                                    <div key={index}>
                                        <CategoriesSettingsItem
                                            item={item}
                                            editButtonCallback={editButtonHandler}
                                            removeItemCallback={removeItemHandler}
                                            discountChangedCallback={discountChangedHandler} />
                                    </div>
                                ))
                            }
                        </Box>
                        <Box sx={{
                            backgroundColor: 'rgba(255,255,255,1)',
                            position: 'fixed',
                            left: contentML,
                            right: '0',
                            bottom: '0',
                            display: 'flex',
                            justifyContent: 'right',
                            p: '10px 20px',
                            zIndex: '10'
                        }}>
                            <Button variant='contained' sx={{ mr: '10px' }} onClick={addButtonHandler}>ADD</Button>
                            <Button variant='contained' onClick={saveButtonHandler}>Save</Button>
                        </Box>
                        <Divider />

                    </>

                ) : (<></>)
            }

            <AddEditCatagoryDialog
                open={open}
                dialogState={dialogState}
                closeDialogHandler={closeDialogHandler}
                addDialogButtonHandler={addDialogButtonHandler}
                editDialogButtonHandler={editDialogButtonHandler}

            />
        </>
    )
}

export default CategoriesSettings