import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGalleryContext } from '../../../providers/GalleryProvider';
import { Close } from '@mui/icons-material';
import { PUBLIC_CDN_URL } from '../../../defs';

const GalleryItem = ({ item }) => {

  const [open, setOpen] = useState(false);

  const { deleteGalleryItem } = useGalleryContext()

  const agreeButoonHandler = () => {
    setOpen(false)

    deleteGalleryItem(item);
  }

  const openDialogHandler = () => {
    setOpen(true)
  }

  const closeDialogHandler = () => {
    setOpen(false)
  }


  return (
    <>
      <Paper sx={{
        p: '1%',
        m: '1%',
        width: '16%',
        height: '20vw',
        float: 'left',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Wrapper>
          <img alt="" src={PUBLIC_CDN_URL + "/static/images/" + item.url} width='100%' />
          <IconWrapper>
            <IconButton onClick={openDialogHandler}>
              <Close sx={{ backgroundColor: '#cccccc', color: 'white' }} />
            </IconButton>
          </IconWrapper>
        </Wrapper>
      </Paper>
      <Dialog
        open={open}
        onClose={closeDialogHandler}
      >
        <DialogTitle>
          {"Внимание"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены что хотите удалить картинку с галлереи ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogHandler}>Disagree</Button>
          <Button onClick={agreeButoonHandler} autoFocus>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GalleryItem;


const Wrapper = styled.div`
  width: '100%';
  height: '100%';
  position: relative;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`