import React from 'react';
import Layout from '../../layout/Layout';

const UsersPage = () => {

  return (
    <Layout>
      Users
    </Layout>
  );
};

export default UsersPage;
