import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { GalleryState } from '../../../defs';
import { useGalleryContext } from '../../../providers/GalleryProvider';
import { useMenuContext } from '../../../providers/MenuProvider';
import UploadImage from './UploadImage';

const GalleryAdd = () => {
  const { setGalleryState, setPage } = useGalleryContext()

  const { contentML } = useMenuContext()

  const onUploadComplete = () => {
    setGalleryState(GalleryState.LIST);
    setPage(1)
  }


  return (
    <>
      <Box sx={{
        position: 'fixed',
        left: contentML,
        right: '0',
        display: 'flex',
        justifyContent: 'space-between',
        p: '20px',
        zIndex: '10'
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'left',
          pl: '10px',
        }}>
          <Button variant='contained' onClick={() => { setGalleryState(GalleryState.LIST) }}>Back</Button>
        </Box>

      </Box>
      <Box sx={{
        position: 'absolute',
        left: contentML,
        top: '100px',
        right: '0',
        bottom: '0',
        pb: '50px'
      }}>
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <UploadImage title={"Главное изображение"} onUploadComplete={onUploadComplete} />
        </Box>
      </Box>
    </>
  );
};

export default GalleryAdd;
