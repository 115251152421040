import { Autocomplete, Box, Button, Pagination, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useProductsContext } from '../../../providers/ProductsProvider';
import ProductListItem from './ProductListItem';
import { MaxProductsItemsPerPage, ProductsGender, ProductsState } from '../../../defs'
import { useMenuContext } from '../../../providers/MenuProvider';
import { animateScroll as scroll } from 'react-scroll'
import { useLayoutContext } from '../../../providers/web_layout/WebLayoutProvider';

const ProductsList = () => {
    const {
        page,
        setPage,

        selectedSex,
        setSelectedSex,

        pagesCount,

        products,
        setProductsState,
        getProducts,
        selectedCategory,
        setSelectedCategory
    } = useProductsContext()

    const { layout } = useLayoutContext()
    const { contentML } = useMenuContext()

    const pageChangeHandler = (e, newValue) => {
        setPage(newValue)
        getProducts(selectedCategory.id, newValue, selectedSex.id)
        scroll.scrollToTop()
    }

    useEffect(() => {
        scroll.scrollToTop()
    }, [products])


    return (
        <>
            {
                layout !== null && selectedCategory !== null ? (
                    <>
                        <Box sx={{
                            backgroundColor: '#ccc',
                            position: 'fixed',
                            left: contentML,
                            right: '0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: '20px',
                            zIndex: '10'
                        }}>
                            <Box sx={{
                                flex: 3
                            }}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    sx={{ flexGrow: 1 }}
                                >
                                    All Products
                                </Typography>
                            </Box>


                            <Box sx={{
                                display: 'flex',
                                flex: 1.4,
                                height: '40px'

                            }}>

                                <Autocomplete
                                    sx={{
                                        mr: '10px',
                                        flex: 1
                                    }}

                                    fullWidth
                                    autoHighlight
                                    disableClearable
                                    options={ProductsGender}
                                    value={selectedSex}
                                    onChange={(event, newValue) => {
                                        getProducts(selectedCategory.id, 1, newValue.id)
                                        setSelectedSex(newValue)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Выберите тип"
                                            size='small'
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />

                                <Autocomplete
                                    sx={{
                                        mr: '10px',
                                        flex: 2
                                    }}
                                    fullWidth
                                    autoHighlight
                                    disableClearable
                                    options={layout.categories.categories_data}
                                    value={selectedCategory}
                                    onChange={(event, newValue) => {
                                        // getProducts(newValue.id, 1, selectedSex.id)
                                        setSelectedCategory(newValue);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Выберите категорию"
                                            size='small'
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                                <Button variant='contained' sx={{
                                    mr: '5px'
                                }} onClick={() => { setProductsState(ProductsState.ADD) }}>ADD</Button>
                            </Box>
                        </Box>

                        <Box sx={{
                            position: 'absolute',
                            top: '150px',
                            left: contentML,
                            right: '0',
                            pb: '50px'
                        }}>
                            <Stack spacing={2} ml={1} mr={1}>


                                {products.map((item, index) => (
                                    <ProductListItem key={index} item={item} />
                                ))}

                            </Stack>
                        </Box>

                        {
                            pagesCount > MaxProductsItemsPerPage ?
                                (
                                    <Box sx={{
                                        backgroundColor: 'rgba(255,255,255,0.5)',
                                        position: 'fixed',
                                        left: contentML,
                                        right: '0',
                                        bottom: '0',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        p: '10px',
                                        zIndex: '10'
                                    }}>
                                        <Pagination count={Math.ceil(pagesCount / MaxProductsItemsPerPage)} page={page} onChange={pageChangeHandler} />
                                    </Box>
                                ) : (<></>)
                        }
                    </>
                ) : (<></>)
            }
        </>
    );
};

export default ProductsList;
