import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'

const PromptingRemoveDialog = (props) => {

  const { open, closeDialogHandler, agreeButoonHandler } = props;

  return (
    <Dialog
      open={open}
      onClose={closeDialogHandler}
    >
      <DialogTitle>
        {"Внимание"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Вы уверены что хотите удалить категроию?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialogHandler}>Disagree</Button>
        <Button onClick={agreeButoonHandler}>Agree</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PromptingRemoveDialog