import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { OrdersState } from '../../../defs'
import { useMenuContext } from '../../../providers/MenuProvider'
import { useOrdersContext } from '../../../providers/OrdersProvider'
import OrderViewerItem from './OrderViewerItem'
import OrderViewerTable from './OrderViewerTable'

const OrderViewer = () => {

  const { contentML } = useMenuContext()

  const { viewData, setOrdersState } = useOrdersContext()


  return (
    <>
      <Box sx={{
        position: 'fixed',
        backgroundColor: 'white',
        left: contentML,
        right: '0',
        display: 'flex',
        flexDirection: 'column',
        p: '10px 20px',
        zIndex: '10',
      }}>
        <Box>
          <Button variant='contained' onClick={(e) => { setOrdersState(OrdersState.LIST) }}>BACK</Button>
        </Box>
      </Box>
      <Box sx={{
        position: 'absolute',
        top: '90px',
        left: contentML,
        right: '0',
        bottom: '0',
        p: '20px',
        overflowX: 'none'
      }}>
        <Box sx={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Paper sx={{ p: '20px', flex: 1, mr: '10px' }}>
            <Stack spacing={1}>

              <OrderViewerItem title="Тип заказа" description="Пока есть два типа заказа, упрощеный(только с номером телефона) и обычный"
                value={viewData.orderType} />
              <OrderViewerItem title="Время заказа" description="Время заказа по времени сервера, сервер в Германии -5 часов от Ташкентского"
                value={viewData.orderTime} />
              <OrderViewerItem title="Имя заказчика" description="Полное имя заказчика"
                value={viewData.customerName} />
              <OrderViewerItem title="Почта заказчика" description="Почтовый адрес заказчика"
                value={viewData.customerEmail} />
              <OrderViewerItem title="Номер заказчика" description="Номер телефона заказчика"
                value={viewData.customerPhone} />
            </Stack>
          </Paper>

          <Paper sx={{ p: '20px', flex: 1, ml: '10px' }}>
            <Stack spacing={1}>
              <OrderViewerItem title="ID" description="Номер заказа" value={viewData.id.toString().padStart(6, '0')} />
              <OrderViewerItem title="Промежуточная цена" description="Цена без учета доставки и скидки"
                value={viewData.subtotalPrice} />
              <OrderViewerItem title="Скидка" description="Цена скидки" value={viewData.discountPrice} />
              <OrderViewerItem title="Доставка" description="Цена доставки" value={viewData.shippingPrice} />
              <OrderViewerItem title="Итого" description="Итоговая цена" value={viewData.totalPrice} />
            </Stack>
          </Paper>
        </Box>
        <Box sx={{
          marginTop: '20px',
          width: '100%'
        }}>
          <Paper sx={{ p: '20px' }}>
            <OrderViewerItem title="Адрес" description="Адрес для доставки клиента" value={viewData.customerAddress} />
          </Paper>
        </Box>



        <Box sx={{
          marginTop: '20px',
          width: '100%'
        }}>
          <Paper sx={{ p: '20px' }}>
            <Typography>Коментарии</Typography>
            <Typography sx={{ fontSize: '12px' }}>Коментарий клиента</Typography>
            <Box sx={{
              margin: '20px 20px',
              display: 'flex',
              justifyContent: 'center'
            }}>
              {viewData.customerNotes}
            </Box>
          </Paper>
        </Box>

        <Box sx={{
          width: '100%',
          marginTop: '20px',
          paddingBottom: '20px'
        }}>
          <OrderViewerTable products={viewData.products} />
        </Box>

      </Box>
    </>
  )
}

export default OrderViewer