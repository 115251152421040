import { Box, Tab, Tabs } from '@mui/material';
import React from 'react'
import { a11yProps } from '../../defs';
import Layout from '../../layout/Layout';
import CategoriesSettingsProvider from '../../providers/web_layout/CategoriesSettingsProvider';
import { useMenuContext } from '../../providers/MenuProvider';
import CategoriesSettings from './components/CategoriesSettings';
import TabPanel from './components/TabPanel';
import ShippingSettings from './components/ShippingSettings';
import ShippingSettingsProvider from '../../providers/web_layout/ShippingSettingsProvider';

const WebLayoutPage = () => {
  const { contentML } = useMenuContext()

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Layout>
      <Box sx={{
        position: 'absolute',
        top: '60px',
        left: contentML,
        right: '0',
        bottom: '0',
        overflowX: 'none'
      }}>

        <Box sx={{
          position: 'fixed',
          left: contentML,
          right: 0,
          borderBottom: 1,
          borderColor: 'divider',
          zIndex: 999
        }}>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            sx={{
              backgroundColor: '#fff'
            }}
          >
            <Tab label="Скидки" {...a11yProps(0)} />
            <Tab label="Доставка" {...a11yProps(1)} />
            <Tab label="Layout" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CategoriesSettingsProvider>
            <CategoriesSettings />
          </CategoriesSettingsProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ShippingSettingsProvider>
            <ShippingSettings />
          </ShippingSettingsProvider>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Coming soon
        </TabPanel>


      </Box>
    </Layout>
  );
}

export default WebLayoutPage