import { Box, Button, Divider, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddShippingDialog from '../../../dialogs/AddShippingDialog'
import { useMenuContext } from '../../../providers/MenuProvider'
import { useShippingSettingsContext } from '../../../providers/web_layout/ShippingSettingsProvider'
import { useLayoutContext } from '../../../providers/web_layout/WebLayoutProvider'
import { getCorrectedDigits } from '../../../utils'
import ShippingSettingsItem from './ShippingSettingsItem'

const ShippingSettings = () => {
    const [open, setOpen] = useState(false)

    const { contentML } = useMenuContext()
    const { layout, saveLayoutChanges } = useLayoutContext()
    const {
        addShippingOption,
        removeShippingOption,
        freeShippingMinPrice,
        setFreeShippingMinPrice
    } = useShippingSettingsContext()

    const addButtonHandler = (e) => {
        setOpen(true);
    }

    const saveButtonHandler = (e) => {
        saveLayoutChanges(layout)
    }

    const removeItemHandler = (item) => {
        removeShippingOption(layout, item)
    }

    const shippingPriceChangedHandler = (item) => {
        const index = layout.cart.shipping.shipping_options.findIndex((data) => data.id === item.id)
        layout.cart.shipping.shipping_options[index] = item;
    }


    const closeDialogHandler = (e) => {
        setOpen(false)
    }

    const addDialogButtonHandler = (e) => {
        setOpen(false)

        addShippingOption(layout)
    }

    useEffect(() => {
        setFreeShippingMinPrice(parseInt(layout.cart.shipping.free_shipping_min_price))
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {
                layout !== null ? (
                    <>
                        <Divider />
                        <Paper sx={{ m: '20px 20px' }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',

                                p: '10px 20px'
                            }}>
                                <Stack direction='column' justifyContent='center'>
                                    <Typography>Бесплатная доставка</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>Сумма после которой доставка будет бесплатной</Typography>
                                </Stack>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        required
                                        sx={{ input: { textAlign: "right" } }}
                                        value={freeShippingMinPrice}
                                        onChange={(e) => {
                                            layout.cart.shipping.free_shipping_min_price = getCorrectedDigits(e.target.value)
                                            setFreeShippingMinPrice(layout.cart.shipping.free_shipping_min_price)
                                        }} />
                                </Box>
                            </Box>
                        </Paper>


                        <Box sx={{
                            m: '10px 20px 20px 20px'
                        }}>
                            {
                                layout.cart.shipping.shipping_options.map((item, index) => (
                                    <div key={index}>
                                        <ShippingSettingsItem
                                            item={item}
                                            removeItemCallback={removeItemHandler}
                                            shippingPriceChangedCallback={shippingPriceChangedHandler} />
                                    </div>
                                ))
                            }
                        </Box>




                        <Box sx={{
                            backgroundColor: 'rgba(255,255,255,1)',
                            position: 'fixed',
                            left: contentML,
                            right: '0',
                            bottom: '0',
                            display: 'flex',
                            justifyContent: 'right',
                            p: '10px 20px',
                            zIndex: '10'
                        }}>
                            <Button variant='contained' sx={{ mr: '10px' }} onClick={addButtonHandler}>ADD</Button>
                            <Button variant='contained' onClick={saveButtonHandler}>Save</Button>
                        </Box>



                        <Divider />


                    </>

                ) : (<></>)
            }
            <AddShippingDialog
                open={open}
                closeDialogHandler={closeDialogHandler}
                addDialogButtonHandler={addDialogButtonHandler}
            />
        </>
    )
}

export default ShippingSettings