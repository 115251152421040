import { Box, Button, CircularProgress, Typography, Pagination } from '@mui/material';
import React from 'react';
import { GalleryState, MaxGalleryItemsPerPage } from '../../../defs';
import { useGalleryContext } from '../../../providers/GalleryProvider';
import { useMenuContext } from '../../../providers/MenuProvider';
import GalleryItem from './GalleryItem';
import { animateScroll as scroll } from 'react-scroll'

const GalleryList = () => {
  const {
    fetching,
    gallery,
    setGalleryState,
    switchToPage,
    pagesCount,
    page,
    setPage
  } = useGalleryContext()
  const { contentML } = useMenuContext()

  const pageChangeHandler = (e, newValue) => {
    setPage(newValue)
    switchToPage(newValue)
    scroll.scrollToTop()
  }

  return (
    <>
      <Box sx={{
        backgroundColor: '#ccc',
        position: 'fixed',
        left: contentML,
        right: '0',
        display: 'flex',
        justifyContent: 'space-between',
        p: '20px',
        zIndex: '10'
      }}>
        <Typography
          component="h1"
          variant="h6"
          sx={{ flexGrow: 1 }}
        >
          Gallery
        </Typography>
        <Button variant='contained' sx={{
          mr: '5px'
        }} onClick={() => { setGalleryState(GalleryState.ADD) }}>ADD</Button>
      </Box>
      <Box sx={{
        position: 'absolute',
        top: '140px',
        left: contentML,
        right: '0',
        display: 'block',
        pb: '50px'
      }}>
        {
          !fetching ? (
            gallery.map((item, index) => (
              <GalleryItem key={index} item={item} />
            ))
          ) : (
            <Box sx={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>
          )
        }
      </Box>

      {
        pagesCount > MaxGalleryItemsPerPage ?
          (
            <Box sx={{
              backgroundColor: '#fff',
              position: 'fixed',
              left: contentML,
              right: '0',
              bottom: '0',
              display: 'flex',
              justifyContent: 'center',
              p: '10px',
              zIndex: '10'
            }}>
              <Pagination count={Math.ceil(pagesCount / MaxGalleryItemsPerPage)} page={page} onChange={pageChangeHandler} />
            </Box>
          ) : (<></>)
      }
    </>
  );
};

export default GalleryList;
