import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import React from 'react'
import { useShippingSettingsContext } from '../providers/web_layout/ShippingSettingsProvider';
import { getCorrectedDigits } from '../utils';

const AddShippingDialog = (props) => {
    const {
        open,
        closeDialogHandler,
        addDialogButtonHandler,
    } = props;

    const {
        label,
        price,

        canAdd,

        setLabel,
        setPrice
    } = useShippingSettingsContext()


    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={open}
            onClose={closeDialogHandler}
        >
            <DialogTitle>
                {"Добавить категорию"}
            </DialogTitle>
            <DialogContent>
                <Stack mt="15px" spacing="10px">
                    <TextField
                        required
                        fullWidth
                        label="Название"
                        value={label}
                        onChange={(e) => { setLabel(e.target.value) }}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Цена"
                        value={price}
                        onChange={(e) => {
                            const price = getCorrectedDigits(e.target.value)
                            setPrice(price)
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialogHandler}>cancle</Button>
                <Button disabled={!canAdd()} onClick={addDialogButtonHandler}>ADD</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddShippingDialog