export const drawerWidth = 240;
export const PUBLIC_CDN_URL = "https://cdn.gunesh.uz";

export const MaxGalleryItemsPerPage = 40;
export const MaxProductsItemsPerPage = 40;
export const MaxOrdersItemsPerPage = 40;
export const MaxStatsItemsPerPage = 40;

export const CategoriesEditorState = {
    ADD: 1,
    EDIT: 2
}

export const OrdersState = {
    LIST: 0,
    ADD: 1,
    VIEW: 3
}

export const ProductsState = {
    LIST: 0,
    ADD: 1,
    EDIT: 2
}

export const GalleryState = {
    LIST: 0,
    ADD: 1
}

export const ProductSex = [
    {
        id: 1,
        name: "Женский"
    },
    {
        id: 2,
        name: "Мужской"
    },
]

export const ProductsGender = [
    {
        id: 0,
        name: "Все"
    },
    {
        id: 1,
        name: "Женский"
    },
    {
        id: 2,
        name: "Мужской"
    },
]

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}