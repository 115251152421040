import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'

const OrderViewerTable = ({ products }) => {


    const formatEmpty = (value) => {
        if (value === "" || value === 0) {
            return "-";
        } else {
            return value
        }


    }



    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Название продукта</TableCell>
                        <TableCell align="right">Продукт (ID)</TableCell>
                        <TableCell align="right">Категория (ID)</TableCell>
                        <TableCell align="right">Цвет продукта</TableCell>
                        <TableCell align="right">Размер продукта</TableCell>
                        <TableCell align="right">Количество</TableCell>
                        <TableCell align="right">Цена продукта</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((item) => (
                        <TableRow key={item.productName}>
                            <TableCell component="th" scope="row">{formatEmpty(item.productName)}</TableCell>
                            <TableCell align="right">{formatEmpty(item.id)}</TableCell>
                            <TableCell align="right">{formatEmpty(item.productCategoryID)}</TableCell>
                            <TableCell align="right">{formatEmpty(item.productColor)}</TableCell>
                            <TableCell align="right">{formatEmpty(item.productSize)}</TableCell>
                            <TableCell align="right">{formatEmpty(item.qty)}</TableCell>
                            <TableCell align="right">{formatEmpty(item.productPrice)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderViewerTable