import {
    Container,
    Avatar,
    Button,
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    FormHelperText,
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import React from 'react'
import Copyright from './Copyright'
import { useAuthContext } from '../../../providers/AuthProvider'

const LoginForm = () => {


    const {
        userName,
        userPassword,
        remember,
        initDataLoaded,

        setUserName,
        setUserPassword,
        setRemember,

        onLogin,
        request,
        hasError
    } = useAuthContext()


    const onRememberChange = () => {
        setRemember(!remember);
    }

    const onAuthSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        onLogin(data.get('email'), data.get('password'));
    }

    return (
        initDataLoaded ? (
            <Container component="main" maxWidth="xs" sx={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'
            }}>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={onAuthSubmit} noValidate sx={{ mt: 1 }}>
                        <FormHelperText error={hasError} sx={{
                            display: hasError ? 'block' : 'none'
                        }} >Неправильно ввели логи или пароль</FormHelperText>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Username"
                            autoFocus
                            disabled={request}
                            value={userName}
                            onChange={(e) => { setUserName(e.target.value) }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            disabled={request}
                            value={userPassword}
                            onChange={(e) => { setUserPassword(e.target.value) }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={remember} onChange={onRememberChange}
                                    value="remember" color="primary" disabled={request} />
                            }
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={request}
                        >
                            Sign In
                        </Button>
                    </Box>
                    <Box sx={{ height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Copyright sx={{ mt: 8, mb: 4 }} />
                    </Box>
                </Box>

            </Container >
        ) : (<></>)

    )
}

export default LoginForm
