import React from 'react'
import Layout from '../../layout/Layout'
import { useOrdersContext } from '../../providers/OrdersProvider'
import { OrdersState } from '../../defs'
import OrdersEditor from './components/OrdersEditor'
import OrdersList from './components/OrdersList'
import OrderViewer from './components/OrderViewer'

const OrdersPage = () => {
    const { ordersState } = useOrdersContext()

    return (
        <Layout>
            {ordersState === OrdersState.LIST ? <OrdersList /> : ordersState === OrdersState.VIEW ? <OrderViewer /> : <OrdersEditor />}
        </Layout>
    );
}

export default OrdersPage
