import React from 'react';
import { GalleryState } from '../../defs';
import Layout from '../../layout/Layout';
import { useGalleryContext } from '../../providers/GalleryProvider';
import GalleryAdd from './components/GalleryAdd';
import GalleryList from './components/GalleryList';

const GalleryPage = () => {

    const { galleryState } = useGalleryContext()

    return (
        <Layout>
            {galleryState === GalleryState.LIST ? <GalleryList /> : <GalleryAdd />}
        </Layout>);
};


export default GalleryPage;
