import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useProductsContext } from '../../../providers/ProductsProvider';
import { ProductsState, PUBLIC_CDN_URL } from '../../../defs'
import { Box } from '@mui/system';
import NumbersIcon from '@mui/icons-material/Numbers';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WcIcon from '@mui/icons-material/Wc';
import styled from 'styled-components';

const ProductListItem = ({ item }) => {
    const [open, setOpen] = useState(false);

    const { setProductsState, deleteProductButtonHandler, initWithData } = useProductsContext()

    const openDialogHandler = () => {
        setOpen(true)
    }

    const closeDialogHandler = () => {
        setOpen(false)
    }

    const agreeButoonHandler = () => {
        setOpen(false)
        deleteProductButtonHandler(item.id)
    }

    const editButtonHandler = () => {
        initWithData(item);
        setProductsState(ProductsState.EDIT);
    }

    return (
        <>
            <Paper
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '20px'
                }}>
                <Stack direction='row'>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center'
                    }}>
                        <Img alt="" src={PUBLIC_CDN_URL + "/static/images/" + item.mainImg} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: '20px'
                    }}>
                        <Stack direction='column'>
                            <Stack spacing={1} direction='row'>
                                <NumbersIcon />
                                <Typography >{item.id.toString().padStart(6, '0')}</Typography>
                            </Stack>
                            <Stack spacing={1} direction='row'>
                                <BorderColorIcon />
                                <Typography >{item.title}</Typography>
                            </Stack>
                            <Stack spacing={1} direction='row'>
                                <VisibilityIcon color='red' />
                                <Typography >{item.hidden ? "Скрыто" : "Видимый"}</Typography>
                            </Stack>
                            <Stack spacing={1} direction='row'>
                                <WcIcon color='red' />
                                <Typography >{item.sex === 1 ? "Женский" : "Мужской"}</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>


                <Stack direction='row' spacing={1}>
                    <Button variant='contained' color='success' onClick={editButtonHandler}>EDIT</Button>
                    <Button variant='contained' color='error' onClick={openDialogHandler}>REMOVE</Button>
                </Stack>
            </Paper >
            <Dialog
                open={open}
                onClose={closeDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Внимание"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы уверены что хотите удалить продукт ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogHandler}>Disagree</Button>
                    <Button onClick={agreeButoonHandler} autoFocus>Agree</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProductListItem;

const Img = styled.img`
    object-fit: cover;
    width:  150px;
    border-radius: 15%;
`