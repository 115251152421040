import React, { createContext, useContext, useEffect, useState } from 'react';
import { MaxOrdersItemsPerPage, OrdersState } from '../defs'
import { useApiContext } from './ApiProvider';

const OrdersContext = createContext()

const OrdersProvider = ({ children }) => {

    const [viewData, setViewData] = useState(null)

    const [ordersState, setOrdersState] = useState(OrdersState.LIST)
    const [orders, setOrders] = useState([])

    const [page, setPage] = useState(0)
    const [rowCount, setRowCount] = useState(0)

    const { getOrdersRequest, deleteOrderRequest } = useApiContext()

    const deleteOrder = (id) => {
        deleteOrderRequest({ id: id, count: MaxOrdersItemsPerPage },
            (data) => {
                setOrders(data.orders)
                setRowCount(data.totalCount)
            },
            (error) => {
                console.log(error)
            })
    }

    const switchToPage = (pageIndex) => {
        getOrdersRequest({ count: MaxOrdersItemsPerPage, pageIndex: pageIndex },
            (data) => {
                setOrders(data.orders)
                setRowCount(data.totalCount)
            }, (error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        switchToPage(page)
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    const contextValue = {
        orders,
        ordersState,
        rowCount,
        page,
        viewData,

        setPage,
        setViewData,

        switchToPage,
        deleteOrder,
        setOrdersState
    }

    return (
        <OrdersContext.Provider value={contextValue}>
            {children}
        </OrdersContext.Provider>
    );
};

export const useOrdersContext = () => useContext(OrdersContext)

export default OrdersProvider;
