import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import PromptingRemoveDialog from '../../../dialogs/PromptingRemoveDialog';
import { getCorrectedDigits } from '../../../utils';


const CategoriesSettingsItem = (props) => {

    const { item, discountChangedCallback, removeItemCallback, editButtonCallback } = props;


    const [open, setOpen] = useState(false)
    const [discount, setDiscout] = useState(item.discount)

    const closeDialogHandler = (e) => {
        setOpen(false)
    }

    const agreeButoonHandler = (e) => {
        removeItemCallback(item)
    }

    const removeItemHandler = (e) => {
        e.stopPropagation()

        setOpen(true)
    }

    const editItemHandler = (e) => {
        e.stopPropagation()

        editButtonCallback(item)
    }

    return (
        <>
            <Accordion >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton onClick={removeItemHandler} >
                            <CancelIcon />
                        </IconButton>
                        <IconButton onClick={editItemHandler} >
                            <EditIcon />
                        </IconButton>
                        <Typography>{item.name}</Typography>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>

                    {discount.map((el, index) => (
                        <Box key={index} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            m: '0px 15px 10px 15px'
                        }}>
                            <Typography>Скидка при покупке {index + 1}шт</Typography>
                            <TextField
                                required
                                placeholder='%'
                                sx={{ input: { textAlign: "right" } }}
                                value={el}
                                onChange={(e) => {
                                    const newDiscount = [...discount]
                                    newDiscount[index] = getCorrectedDigits(e.target.value);
                                    setDiscout(newDiscount)

                                    item.discount = newDiscount;
                                    discountChangedCallback(item)
                                }} />
                        </Box>
                    ))}


                </AccordionDetails>

            </Accordion >
            <PromptingRemoveDialog open={open} closeDialogHandler={closeDialogHandler} agreeButoonHandler={agreeButoonHandler} />
        </>
    )
}

export default CategoriesSettingsItem