import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Stack, Switch, TextField } from '@mui/material'
import React from 'react'
import { CategoriesEditorState } from '../defs';
import { useCategoriesContext } from '../providers/web_layout/CategoriesSettingsProvider';

const AddEditCatagoryDialog = (props) => {

  const {
    open,
    dialogState,
    closeDialogHandler,
    addDialogButtonHandler,
    editDialogButtonHandler
  } = props;


  const {
    name,
    icon,
    cover,
    manCover,
    womanCover,
    hidden,
    hasGenderFilter,

    canAdd,

    setName,
    setIcon,
    setCover,
    setManCover,
    setWomanCover,
    setHidden,
    setHasGenderFilter
  } = useCategoriesContext()


  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onClose={closeDialogHandler}
    >
      <DialogTitle>
        {"Добавить категорию"}
      </DialogTitle>
      <DialogContent>
        <Stack mt="15px" spacing="10px">
          <TextField
            required
            fullWidth
            label="Название"
            value={name}
            onChange={(e) => { setName(e.target.value) }} />
          <TextField
            required
            fullWidth
            label="Иконка"
            value={icon}
            onChange={(e) => { setIcon(e.target.value) }} />
          <TextField
            required
            fullWidth
            label="Кавер"
            value={cover}
            onChange={(e) => { setCover(e.target.value) }} />
          <FormGroup>
            <FormControlLabel control={<Switch checked={hasGenderFilter} onChange={(e) => { setHasGenderFilter(e.target.checked) }} />} label="Имеет гендерный фильтр" />

            {
              hasGenderFilter ? (
                <Stack mt="15px" spacing="10px">
                  <TextField
                    required
                    fullWidth
                    label="Кавер мужской"
                    value={manCover}
                    onChange={(e) => { setManCover(e.target.value) }} />
                  <TextField
                    required
                    fullWidth
                    label="Кавер женский"
                    value={womanCover}
                    onChange={(e) => { setWomanCover(e.target.value) }} />

                </Stack>
              ) : (<></>)
            }


            <FormControlLabel control={<Switch checked={hidden} onChange={(e) => { setHidden(e.target.checked) }} />} label="Скрытый в основном сайте" />
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialogHandler}>cancle</Button>

        {
          dialogState === CategoriesEditorState.ADD ?
            (<Button disabled={!canAdd()} onClick={addDialogButtonHandler}>ADD</Button>)
            :
            (<Button disabled={!canAdd()} onClick={editDialogButtonHandler}>EDIT</Button>)
        }



      </DialogActions>
    </Dialog>
  )
}

export default AddEditCatagoryDialog