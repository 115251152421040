import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApiContext } from './ApiProvider';
import { MaxProductsItemsPerPage, ProductSex, ProductsGender, ProductsState } from '../defs'
import { useLayoutContext } from './web_layout/WebLayoutProvider';

const ProductsContext = createContext()

const ProductsProvider = ({ children }) => {
    const [productsState, setProductsState] = useState(ProductsState.LIST);
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSex, setSelectedSex] = useState(ProductsGender[0])

    const [page, setPage] = useState(1)
    const [pagesCount, setPagesCount] = useState(48)

    const [id, setId] = useState(-1)
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState(null);
    const [price, setPrice] = useState('');
    const [oldPrice, setOldPrice] = useState('');
    const [sex, setSex] = useState(null);
    const [tags, setTags] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [stars, setStars] = useState(0);
    const [hidden, setHidden] = useState(false);
    const [gallery, setGallery] = useState([])

    const { layout } = useLayoutContext()

    const {
        getProductsRequest,
        addProductRequest,
        deleteProductRequest,
        editProductRequest
    } = useApiContext()


    const initWithData = (item) => {
        setId(item.id)
        setTitle(item.title);
        setCategory(layout.categories.categories_data.find((i) => item.categoryId === i.id));
        setPrice(item.price);
        setOldPrice(item.oldPrice);
        setTags(item.tags);
        setSex(ProductSex.find((i) => item.sex === i.id));
        setShortDescription(item.shortDescription);
        setDescription(item.description);
        setStars(item.stars)
        setHidden(item.hidden)
        setGallery(item.gallery)
    }

    const initWithEmptyData = () => {
        setId(-1)
        setTitle("");
        setCategory(null);
        setPrice("");
        setOldPrice("");
        setSex(null);
        setTags("");
        setShortDescription("");
        setDescription("");
        setStars(0);
        setHidden(false)
        setGallery([]);
    }


    const canCreate = () => {
        return (
            title !== '' && category !== null &&
            price !== '' && oldPrice !== '' && tags !== '' && shortDescription !== ''
            && description !== '' && gallery.length > 0)
    }

    const errorHandler = (error) => {
        console.log(error)
    }

    const createProductButtonHandler = () => {
        addProductRequest({
            orderBy: "id desc", count: MaxProductsItemsPerPage, productData: {
                title: title,
                categoryId: category.id,
                price: price,
                oldPrice: oldPrice,
                tags: tags,
                sex: sex.id,
                shortDescription: shortDescription,
                description: description,
                mainImg: gallery[0],
                stars: stars,
                hidden: hidden,
                reviews: [],
                gallery: [...gallery]
            }
        }, (data) => {
            setProducts(data.products);
            setPagesCount(data.totalCount);
            setSelectedCategory(layout.categories.categories_data.find((item) => (item.id === category.id)))
            setProductsState(ProductsState.LIST);
        }, errorHandler)
    }

    const editProductButtonHandler = () => {
        editProductRequest({
            orderBy: "id desc", pageIndex: page - 1, count: MaxProductsItemsPerPage, productData: {
                id: id,
                title: title,
                categoryId: category.id,
                price: price,
                oldPrice: oldPrice,
                tags: tags,
                sex: sex.id,
                shortDescription: shortDescription,
                description: description,
                mainImg: gallery[0],
                stars: stars,
                hidden: hidden,
                reviews: [],
                gallery: [...gallery]
            }
        }, (data) => {
            setProducts(data.products);
            setPagesCount(data.totalCount);
            setSelectedCategory(layout.categories.categories_data.find((item) => (item.id === category.id)))
            setProductsState(ProductsState.LIST);
        }, errorHandler)
    }

    const deleteProductButtonHandler = (id) => {
        deleteProductRequest({
            id: id,
            categoryId: selectedCategory.id,
            orderBy: "id desc",
            count: MaxProductsItemsPerPage
        }, (data) => {
            setProducts(data.products);
            setPagesCount(data.totalCount);
        }, errorHandler);
    }

    const addToGallery = (src) => {
        const newGallery = [...gallery]
        newGallery.push(src)

        setGallery(newGallery)
    }

    const deleteFromGallery = (src) => {
        const newGallery = [...gallery];
        const index = newGallery.findIndex((item) => item === src);
        newGallery.splice(index, 1);

        setGallery(newGallery)
    }

    const getProducts = (categoryId, pageIndex, sexId) => {
        getProductsRequest({ categoryId: categoryId, sex: sexId, count: MaxProductsItemsPerPage, orderBy: 'id desc', pageIndex: pageIndex - 1 },
            (data) => {
                setProducts(data.products);
                setPagesCount(data.totalCount);
            }, errorHandler)
    }



    useEffect(() => {
        if (layout !== null) {
            setSelectedCategory(layout.categories.categories_data[0])
            // console.log(layout.categories.categories_data)
        }
    }, [layout]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCategory !== null) {
            setPage(1)
            getProducts(selectedCategory.id, 1, selectedSex.id)
        }
    }, [selectedCategory]);//eslint-disable-line react-hooks/exhaustive-deps

    const contextValue = {
        products,
        productsState,
        selectedCategory,

        title,
        category,
        price,
        oldPrice,
        tags,
        sex,
        shortDescription,
        description,
        stars,
        hidden,
        gallery,

        page,
        pagesCount,

        setTitle,
        setCategory,
        setPrice,
        setOldPrice,
        setTags,
        setSex,
        setShortDescription,
        setDescription,
        setStars,
        setHidden,
        setSelectedCategory,

        setPage,
        setPagesCount,

        selectedSex,
        setSelectedSex,

        getProducts,
        addToGallery,
        deleteFromGallery,
        initWithData,
        initWithEmptyData,
        canCreate,
        setProductsState,
        editProductButtonHandler,
        deleteProductButtonHandler,
        createProductButtonHandler
    }

    return (
        <ProductsContext.Provider value={contextValue}>
            {children}
        </ProductsContext.Provider>
    );
};

export const useProductsContext = () => useContext(ProductsContext)

export default ProductsProvider;
