import { Box } from '@mui/material';
import React from 'react';
import Layout from '../../layout/Layout';
import { useMenuContext } from '../../providers/MenuProvider';
import LeadsTable from './components/LeadsTable';

const LeadsPage = () => {

    const { contentML } = useMenuContext()
    return (
        <Layout>
            <Box sx={{
                position: 'absolute',
                top: '60px',
                left: contentML,
                right: '0',
                bottom: '0',
                overflowX: 'none'
            }}>
                <LeadsTable />

            </Box>
        </Layout>
    );
};

export default LeadsPage;
