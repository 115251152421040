import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PageviewIcon from '@mui/icons-material/Pageview';
import { MaxOrdersItemsPerPage, OrdersState } from '../../../defs';
import { useOrdersContext } from '../../../providers/OrdersProvider';
import { useMenuContext } from '../../../providers/MenuProvider';


const OrdersList = () => {
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState(0)

  const {
    setOrdersState,
    orders,
    rowCount,
    page,
    setPage,
    deleteOrder,
    switchToPage,
    setViewData
  } = useOrdersContext()

  const { contentML } = useMenuContext()


  const agreeButoonHandler = () => {
    setOpen(false);
    deleteOrder(currentId);
  }

  const closeDialogHandler = () => {
    setOpen(false)
  }

  const exportCSV = () => {
    console.log('export')
  }


  const onDataGridPageChange = (newPage) => {
    setPage(newPage)
    switchToPage(newPage)
  }

  const handleDeleteOrder = useCallback(
    (id) => () => {
      setCurrentId(id)
      setOpen(true)
    },
    [],
  );

  const handleEditOrder = useCallback(
    (id) => () => {
      console.log(id)
    },
    [],
  );

  const handleViewOrder = useCallback(
    (item) => () => {
      setViewData(item);
      setOrdersState(OrdersState.VIEW);
    },
    [setViewData, setOrdersState],
  );

  const columns = useMemo(
    () => [
      {
        field: 'id', headerName: "ID", type: 'number', flex: 2,
        renderCell: (params) => {
          return (params.value.toString().padStart(6, '0'));
        },
      },
      { field: 'orderTime', headerName: "Время заказа", type: 'dateTime', flex: 6 },
      { field: 'customerName', headerName: "Имя", type: 'string', flex: 6 },
      { field: 'customerPhone', headerName: "Телефон", type: 'string', flex: 6 },
      { field: 'customerAddress', headerName: "Адрес", type: 'string', flex: 6 },
      { field: 'discountPrice', headerName: "Скидка", type: 'string', flex: 6, headerAlign: 'right', align: 'right' },
      { field: 'shippingPrice', headerName: "Доставка", type: 'string', flex: 6, headerAlign: 'right', align: 'right' },
      {
        field: 'totalPrice', headerName: "Итого", type: 'string', flex: 6, headerAlign: 'right', align: 'right'
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<PageviewIcon />}
            label="View"
            onClick={handleViewOrder(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditOrder(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteOrder(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [handleViewOrder, handleEditOrder, handleDeleteOrder],
  );




  return (

    <>
      <Box sx={{
        backgroundColor: '#ccc',
        position: 'fixed',
        left: contentML,
        right: '0',
        display: 'flex',
        justifyContent: 'space-between',
        p: '20px',
        zIndex: '10'
      }}>
        <Typography
          component="h1"
          variant="h6"
          sx={{ flexGrow: 1 }}
        >
          All Orders
        </Typography>
        <Box>
          <Button variant='contained' sx={{ mr: '10px' }} onClick={exportCSV}>EXPORT</Button>
          <Button variant='contained' onClick={() => { setOrdersState(OrdersState.ADD) }}>ADD</Button>
        </Box>
      </Box>
      <Box sx={{
        position: 'absolute',
        top: '140px',
        left: contentML,
        right: '0',
        bottom: '0',
        overflowX: 'none'
      }}>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid hideFooterSelectedRowCount
            page={page}
            paginationMode="server"
            columns={columns}
            rows={orders}
            rowCount={rowCount}
            pageSize={MaxOrdersItemsPerPage}
            rowsPerPageOptions={[MaxOrdersItemsPerPage]}
            onPageChange={onDataGridPageChange}
            onPageSizeChange={(pageSize) => { console.log("pageSize: " + pageSize) }} />
        </div>
      </Box>




      <Dialog
        open={open}
        onClose={closeDialogHandler}
      >
        <DialogTitle>
          {"Внимание"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены что хотите удалить заказ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogHandler}>Disagree</Button>
          <Button onClick={agreeButoonHandler}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrdersList;
