import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import DashboardPage from './pages/dashboard/DashboardPage';
import OrdersPage from './pages/orders/OrdersPage';
import LoginPage from './pages/login/LoginPage';
import MenuProvider from './providers/MenuProvider';
import ProductsPage from './pages/products/ProductsPage';
import UsersPage from './pages/users/UsersPage';
import StatsPage from './pages/reports/StatsPage';
import ProductsProvider from './providers/ProductsProvider';
import ApiProvider from './providers/ApiProvider';
import GalleryPage from './pages/gallery/GalleryPage';
import GalleryProvider from './providers/GalleryProvider';
import AuthProvider from './providers/AuthProvider';
import OrdersProvider from './providers/OrdersProvider';
import StatsProvider from './providers/StatsProvider';
import LeadsPage from './pages/leads/LeadsPage';
import LeadsProvider from './providers/LeadsProvider';
import WebLayoutPage from './pages/web_layout/WebLayoutPage';
import WebLayoutProvider from './providers/web_layout/WebLayoutProvider';

function App() {
  return (
    <ApiProvider>
      <WebLayoutProvider>
        <MenuProvider>
          <GalleryProvider>
            <Router>
              <AuthProvider>
                <Routes>
                  <Route path='/' element={<LoginPage />} exact />
                  <Route path='/login' element={<LoginPage />} exact />
                  <Route path='/dashboard' element={<DashboardPage />} exact />
                  <Route path='/orders' element={<OrdersProvider><OrdersPage /></OrdersProvider>} exact />
                  <Route path='/web-layout' element={<WebLayoutPage />} exact />
                  <Route path='/users' element={<UsersPage />} exact />
                  <Route path='/products' element={<ProductsProvider><ProductsPage /></ProductsProvider>} exact />
                  <Route path='/gallery' element={<GalleryPage />} exact />
                  <Route path='/leads' element={<LeadsProvider><LeadsPage /></LeadsProvider>} exact />
                  <Route path='/stats' element={<StatsProvider><StatsPage /></StatsProvider>} exact />
                </Routes>
              </AuthProvider>
            </Router>
          </GalleryProvider>
        </MenuProvider>
      </WebLayoutProvider>
    </ApiProvider>
  );
}

export default App;
