import React, { createContext, useContext, useEffect, useState, } from 'react'
import { MaxStatsItemsPerPage } from '../defs'
import { useApiContext } from './ApiProvider'

const LeadsContext = createContext()

const LeadsProvider = ({ children }) => {
    const { getLeadsRequest, deleteLeadRequest } = useApiContext()

    const [leads, setLeads] = useState([])
    const [page, setPage] = useState(0)
    const [rowCount, setRowCount] = useState(0)

    const switchToPage = (pageIndex) => {
        getLeadsRequest({ count: MaxStatsItemsPerPage, pageIndex: pageIndex },
            (data) => {
                setLeads(data.stats)
                setRowCount(data.totalCount)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const deleteLead = (id) => {
        deleteLeadRequest({ id: id, count: MaxStatsItemsPerPage },
            (data) => {
                setLeads(data.stats)
                setRowCount(data.totalCount)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    useEffect(() => {
        switchToPage(page)
    }, [])//eslint-disable-line react-hooks/exhaustive-deps



    const contextValue = {
        leads,
        rowCount,

        setPage,
        switchToPage,
        deleteLead
    }

    return (
        <LeadsContext.Provider value={contextValue}>
            {children}
        </LeadsContext.Provider>
    )
}

export const useLeadsContext = () => useContext(LeadsContext)

export default LeadsProvider