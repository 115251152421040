import React, { createContext, useContext, useEffect, useState } from 'react'
import md5 from 'md5'
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiContext } from './ApiProvider';

const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [request, setRequest] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [initDataLoaded, setInitDataLoaded] = useState(false)


    const navigate = useNavigate()
    const location = useLocation()

    const { onAuthRequest } = useApiContext()

    useEffect(() => {
        const authLocalData = localStorage.getItem('authLocalData')

        if (authLocalData === null || authLocalData === 'null') {
            setInitDataLoaded(true)
            navigate("/login", { replace: true })
        } else {
            const data = JSON.parse(authLocalData)

            if (data.remember === false) {
                setInitDataLoaded(true)
                navigate("/login", { replace: true })
            } else {
                onAuthRequest({
                    username: data.username,
                    password: data.password
                }, () => {
                    if (location.pathname === "/" || location.pathname === "/login") {
                        setInitDataLoaded(true)
                        navigate('/dashboard', { replace: true })
                    } else {
                        setInitDataLoaded(true)
                        navigate(location.pathname, { replace: true })
                    }
                }, (error) => {
                    console.log(error)
                    setInitDataLoaded(true)
                    navigate("/login", { replace: true })
                })
            }
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    const onLogin = () => {
        setRequest(true);

        if (userName === "" && userPassword === "") {
            errorHandler("Empty fields")
        } else {
            onAuthRequest({
                username: userName,
                password: md5(userPassword)
            }, onCompleteAuth, errorHandler)
        }
    }

    const errorHandler = (error) => {
        setRequest(false);
        setHasError(true);
        console.log(error)
    }

    const onCompleteAuth = () => {
        localStorage.setItem('authLocalData', JSON.stringify({ username: userName, password: md5(userPassword), remember: remember }));

        setRequest(false);
        navigate('/dashboard', { replace: true })
    }

    const contextValue = {
        userName,
        userPassword,
        remember,
        request,
        hasError,
        initDataLoaded,

        onLogin,
        setUserName,
        setUserPassword,
        setRemember
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
