import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGalleryContext } from '../../../providers/GalleryProvider';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const UploadImage = ({ title, onUploadComplete }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [uploading, setUploading] = useState(false);

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        aspect: 650 / 773
    })
    const [completedCrop, setCompletedCrop] = useState(null);

    const { uploadImage } = useGalleryContext()

    const onUploadClick = async () => {
        const blob = await new Promise(resolve => previewCanvasRef.current.toBlob(resolve, "image/jpeg", 0.50));

        // const previewUrl = window.URL.createObjectURL(blob);
        // const anchor = document.createElement('a');
        // anchor.download = 'cropPreview.jpg';
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click();
        // window.URL.revokeObjectURL(previewUrl);

        setUploading(true)

        uploadImage(blob, () => {
            setUploading(false)
            onUploadComplete()
        }, (error) => {
            console.log(error)
            setUploading(false);
        })
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    return (
        <Paper sx={{
            p: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography
                sx={{
                    m: '10px'
                }}>
                {title}
            </Typography>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <input accept="image/*" type="file" id="select-image" style={{ display: 'none' }}
                    onChange={e => setSelectedImage(e.target.files[0])}
                />
                <label htmlFor="select-image">
                    <Button variant="contained" color="primary" component="span">
                        Browse Image
                    </Button>
                </label>

                {imageUrl && selectedImage && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: '10px'
                    }}>
                        <ReactCrop
                            style={{ width: '50%' }}
                            src={imageUrl}
                            crop={crop}
                            onChange={newCrop => setCrop(newCrop)}
                            onImageLoaded={onLoad}
                            onComplete={(c) => setCompletedCrop(c)}
                        />

                        <div>
                            <canvas
                                ref={previewCanvasRef}
                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                style={{
                                    display: 'none',
                                    width: Math.round(completedCrop?.width ?? 0),
                                    height: Math.round(completedCrop?.height ?? 0)
                                }}
                            />
                        </div>


                        {/* <img alt="" src={imageUrl} width='80%' /> */}
                        {

                            uploading ? <CircularProgress sx={{ mt: '10px' }} /> : (<Button variant="contained" color="success" sx={{ mt: '10px' }}
                                onClick={onUploadClick}>Upload</Button>)
                        }

                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default UploadImage;
