import React, { createContext, useState, useContext, useEffect } from 'react'
import { MaxStatsItemsPerPage } from '../defs'
import { useApiContext } from './ApiProvider'

const StatsContext = createContext()

const StatsProvider = ({ children }) => {
    const { getStatsRequest, clearStatsRequest } = useApiContext()

    const [stats, setStats] = useState([])
    const [page, setPage] = useState(0)
    const [rowCount, setRowCount] = useState(0)

    const switchToPage = (pageIndex) => {
        getStatsRequest({ count: MaxStatsItemsPerPage, pageIndex: pageIndex },
            (data) => {
                setStats(data.stats)
                setRowCount(data.totalCount)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const clearStats = () => {
        clearStatsRequest({},
            (data) => {
                setStats(data.stats)
                setRowCount(data.totalCount)
            },
            (error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        switchToPage(page)
    }, [])//eslint-disable-line react-hooks/exhaustive-deps



    const contextValue = {
        stats,
        rowCount,

        setPage,
        switchToPage,
        clearStats
    }

    return (
        <StatsContext.Provider value={contextValue}>
            {children}
        </StatsContext.Provider>
    )
}

export const useStatsContext = () => useContext(StatsContext)

export default StatsProvider