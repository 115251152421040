import React, { createContext, useContext, useEffect, useState } from 'react'
import { useApiContext } from '../ApiProvider'

const WebLayoutContext = createContext()

const WebLayoutProvider = ({ children }) => {
    const [layout, setLayout] = useState(null)

    const { getLayoutRequest, editLayoutRequest } = useApiContext()


    const saveLayoutChanges = (layout) => {
        editLayoutRequest({ layoutData: JSON.stringify(layout) },
            (data) => {
                const layoutData = JSON.parse(data.layoutData);
                setLayout(layoutData)
            },
            (error) => {
                console.log(error)
            }
        )
    }


    useEffect(() => {
        getLayoutRequest(
            (data) => {
                setLayout(data)
            },
            (error) => {
                console.log(error)
            })
    }, [])//eslint-disable-line react-hooks/exhaustive-deps

    const contextValue = {
        layout,

        setLayout,
        saveLayoutChanges
    }

    return (
        <WebLayoutContext.Provider value={contextValue}>
            {children}
        </WebLayoutContext.Provider>
    )
}

export const useLayoutContext = () => useContext(WebLayoutContext)

export default WebLayoutProvider