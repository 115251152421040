import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useMemo, useCallback, useState } from 'react'
import { MaxStatsItemsPerPage } from '../../../defs';
import { useLeadsContext } from '../../../providers/LeadsProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import PageviewIcon from '@mui/icons-material/Pageview';

const LeadsTable = () => {

    const { leads, page, rowCount, setPage, switchToPage, deleteLead } = useLeadsContext()

    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = useState(0)

    const agreeButoonHandler = () => {
        setOpen(false);
        deleteLead(currentId);
    }

    const closeDialogHandler = () => {
        setOpen(false)
    }

    const handleDeleteOrder = useCallback(
        (id) => () => {
            setCurrentId(id)
            setOpen(true)
        },
        [],
    );

    const columns = useMemo(
        () => [
            {
                field: 'id', headerName: "ID", type: 'number', flex: 1,
                renderCell: (params) => {
                    return (params.value.toString().padStart(6, '0'));
                },
            },
            { field: 'customerName', headerName: "Имя заказчика", headerAlign: 'right', align: 'right', type: 'string', flex: 3 },
            { field: 'customerPhone', headerName: "Телефон заказчика", headerAlign: 'right', align: 'right', type: 'string', flex: 3 },
            {
                field: 'actions',
                type: 'actions',
                flex: 1,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<PageviewIcon />}
                        label="View"
                        onClick={() => { }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteOrder(params.id)}
                        showInMenu
                    />,
                ],
            },
        ], [handleDeleteOrder]
    );


    const onDataGridPageChange = (newPage) => {
        setPage(newPage)
        switchToPage(newPage)
    }


    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                    hideFooterSelectedRowCount
                    paginationMode="server"
                    columns={columns}
                    rows={leads}

                    page={page}
                    rowCount={rowCount}
                    pageSize={MaxStatsItemsPerPage}
                    rowsPerPageOptions={[MaxStatsItemsPerPage]}
                    onPageChange={onDataGridPageChange}
                // onPageSizeChange={(pageSize) => { console.log("pageSize: " + pageSize) }}
                />
            </div>
            <Dialog
                open={open}
                onClose={closeDialogHandler}
            >
                <DialogTitle>
                    {"Внимание"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены что хотите удалить заказ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogHandler}>Disagree</Button>
                    <Button onClick={agreeButoonHandler}>Agree</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default LeadsTable