import React, { createContext, useContext, useEffect, useState } from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import ImageIcon from '@mui/icons-material/Image';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CategoryIcon from '@mui/icons-material/Category';

const MenuContext = createContext();

const menuData = [
    {
        id: 1,
        label: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/dashboard'
    },
    {
        id: 2,
        label: 'Orders',
        icon: <ShoppingCartIcon />,
        path: '/orders'
    },
    {
        id: 3,
        label: 'Layout',
        icon: <CategoryIcon />,
        path: '/web-layout'
    },
    {
        id: 4,
        label: 'Users',
        icon: <PeopleIcon />,
        path: '/users'
    },
    {
        id: 5,
        label: 'Products',
        icon: <LayersIcon />,
        path: '/products'
    },
    {
        id: 6,
        label: 'Gallery',
        icon: <ImageIcon />,
        path: '/gallery'
    },
    {
        id: 7,
        label: 'Leads',
        icon: <PhoneCallbackIcon />,
        path: '/leads'
    },
    {
        id: 8,
        label: 'Stats',
        icon: <BarChartIcon />,
        path: '/stats'
    },

];

const additionalMenuData = [
    {
        id: 1,
        label: 'Current month',
        icon: <AssignmentIcon />,
        path: '/dashboard'
    },
    {
        id: 2,
        label: 'Last quarter',
        icon: <AssignmentIcon />,
        path: '/dashboard'
    },
    {
        id: 3,
        label: 'Year-end sale',
        icon: <AssignmentIcon />,
        path: '/dashboard'
    }
]

const MenuProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [headerLabel, setHeaderLabel] = useState(menuData[0].label)
    const [contentVW, setContentVW] = useState('96vw')
    const [contentML, setContentML] = useState('100px')

    useEffect(() => {
        if (isOpen) {
            setContentVW('85vw')
            setContentML('240px')
        } else {
            setContentVW('96vw')
            setContentML('73px')
        }
    }, [isOpen]);


    const handleMenuOpen = () => {
        setIsOpen(!isOpen);
    }

    const updateHeaderLabel = (label) => {
        setHeaderLabel(label);
    }


    const contextValue = {
        isOpen,
        headerLabel,
        menuData,
        additionalMenuData,
        contentVW,
        contentML,

        updateHeaderLabel,
        handleMenuOpen
    }

    return (
        <MenuContext.Provider value={contextValue}>
            {children}
        </MenuContext.Provider>
    )
}

export const useMenuContext = () => useContext(MenuContext)

export default MenuProvider
