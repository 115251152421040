import React from 'react';
import Layout from '../../layout/Layout';
import { useProductsContext } from '../../providers/ProductsProvider';
import ProductsList from './components/ProductsList'
import ProductsEditor from './components/ProductsEditor'
import { ProductsState } from '../../defs'

const ProductsPage = () => {
    const { productsState } = useProductsContext()

    return (
        <Layout>
            {productsState === ProductsState.LIST ? <ProductsList /> : <ProductsEditor productsState={productsState} />}
        </Layout>
    );
};

export default ProductsPage;
