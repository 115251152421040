import { Button, Dialog, DialogContent, DialogTitle, Pagination, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styled from 'styled-components';
import { PUBLIC_CDN_URL } from '../../../defs';
import { useGalleryContext } from '../../../providers/GalleryProvider';
import { useProductsContext } from '../../../providers/ProductsProvider';
import UploadImage from '../../gallery/components/UploadImage';

const SelectImageDialog = () => {

    const [open, setOpen] = React.useState(false);
    const [showUpload, setShowUpload] = React.useState(false);


    const {
        gallery,
        pagesCount,
        page,
        setPage,
        switchToPage
    } = useGalleryContext()
    const { addToGallery } = useProductsContext()

    const pageChangeHandler = (e, newValue) => {
        setPage(newValue);
        switchToPage(newValue)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onItemSelect = (item) => {
        addToGallery(item.url)

        setOpen(false);
    }

    const onUploadComplete = () => {
        setShowUpload(false);
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Typography>
                    Добавить картинки
                </Typography>
                <Button variant='contained' onClick={handleClickOpen}>ADD</Button>
            </Box>
            <Dialog
                // fullWidth={true}
                // maxWidth='xl'
                open={open}
                onClose={handleClose}
                scroll='paper'
            >
                <DialogTitle>Выбурите картинку</DialogTitle>
                <DialogContent dividers={true}>
                    {showUpload ?
                        (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <UploadImage title='Загрузить картинку' onUploadComplete={onUploadComplete} />
                            </Box>
                        ) : (
                            <Box>
                                <Box sx={{
                                    width: '100%',
                                    display: 'inline-block',
                                    // flexWrap: 'wrap',
                                    // alignContent: 'flex-start',
                                }}>
                                    {
                                        gallery.map((item, index) => (
                                            <Paper key={index}
                                                sx={{
                                                    p: '1%',
                                                    m: '1%',
                                                    float: 'left',
                                                    width: '16%',
                                                    height: '110px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <ImgWrapper onClick={() => onItemSelect(item)}>
                                                    <img alt="" src={PUBLIC_CDN_URL + "/static/images/" + item.url} width='100%' />
                                                </ImgWrapper>
                                            </Paper>
                                        ))
                                    }
                                </Box>
                            </Box>
                        )
                    }
                </DialogContent>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    m: '10px'
                }}>

                    <Button onClick={() => { setShowUpload(!showUpload) }}>{showUpload ? "List" : "Upload"}</Button>
                    {showUpload ? (<></>) : (
                        (pagesCount > 50) ? (
                            <Pagination count={Math.ceil(pagesCount / 50)} page={page} onChange={pageChangeHandler} />
                        ) : (
                            <></>
                        )
                    )}
                    <Button onClick={handleClose}>Cancel</Button>
                </Box>
            </Dialog>

        </>
    );
};

export default SelectImageDialog;


const ImgWrapper = styled.div`
    cursor: pointer;
`