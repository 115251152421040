import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const OrderViewerItem = ({ title, description, value }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <Stack direction='column' justifyContent='center'>
                <Typography>{title}</Typography>
                <Typography sx={{ fontSize: '12px' }}>{description}</Typography>
            </Stack>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{value}</Typography>
            </Box>
        </Box>
    )
}

export default OrderViewerItem