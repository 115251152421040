import axios from 'axios';
import React, { createContext, useContext } from 'react';


const ApiContext = createContext()

const ApiProvider = ({ children }) => {

    const PUBLIC_URL = "https://api.gunesh.uz";
    // const PUBLIC_URL = "http://127.0.0.1:8081";

    const onAuthRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/auth', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback();
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    //#region layout requests
    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const getLayoutRequest = (completeCallback, errorCallbcack) => {
        axios.get(PUBLIC_URL + '/v1/admin/layout/get')
            .then((response) => {
                const data = response.data;
                if (data.has_error === true) {
                    errorCallbcack("has error");
                } else {
                    completeCallback(data);
                }
            }).catch((error) => {
                errorCallbcack(error);
            });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const editLayoutRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/layout/edit', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }
    //#endregion


    //#region orders requests

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const addOrderRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/orders/add', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const getOrdersRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/orders/get', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const deleteOrderRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/orders/delete', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }


    //#endregion

    //#region products requests


    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const getProductsRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/products/get', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const addProductRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/product/add', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const editProductRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/product/edit', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const deleteProductRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/product/delete', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    //#endregion


    //#region gallery requests

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const uploadImageRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/gallery/add', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const getGalleryRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/gallery/get', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const deleteImageRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/gallery/delete', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    //#endregion

    //#region stats requests

    const getStatsRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/stats/get', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    const clearStatsRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/stats/clear', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }


    //#endregion


    //#region leads requests

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const getLeadsRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/leads/get', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    /**
     * 
     * @param {*} data 
     * @param {*} completeCallback 
     * @param {*} errorCallbcack 
     */
    const deleteLeadRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/admin/leads/delete', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    //#endregion

    const contextValue = {
        onAuthRequest,

        getLayoutRequest,
        editLayoutRequest,

        getProductsRequest,
        addProductRequest,
        editProductRequest,
        deleteProductRequest,

        uploadImageRequest,
        getGalleryRequest,
        deleteImageRequest,

        addOrderRequest,
        getOrdersRequest,
        deleteOrderRequest,

        getStatsRequest,
        clearStatsRequest,

        getLeadsRequest,
        deleteLeadRequest,
    }

    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApiContext = () => useContext(ApiContext)

export default ApiProvider;
