import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Layout from '../../layout/Layout'
import IncomeChart from './components/IncomeChart'
import OrdersChart from './components/OrdersChart'

const DashboardPage = () => {

    return (
        <Layout>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mr: '25px'
                }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            mb: '20px'
                        }}>
                        <OrdersChart />
                    </Paper>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            mb: '20px'
                        }}>
                        <OrdersChart />
                    </Paper>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center'
                }}>
                    <Paper
                        sx={{
                            width: '380px',
                            height: '330px',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: '20px'
                        }}>
                        <IncomeChart />
                    </Paper>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            mb: '20px'
                        }}>
                        <OrdersChart />
                    </Paper>
                </Box>
            </Box>
        </Layout >

    )
}

export default DashboardPage
