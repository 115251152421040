import React, { createContext, useContext, useState } from 'react'
import { useApiContext } from '../ApiProvider'
import { useLayoutContext } from './WebLayoutProvider'

const ShippingSettingsContext = createContext()

const ShippingSettingsProvider = ({ children }) => {
  const { editLayoutRequest } = useApiContext()
  const { setLayout } = useLayoutContext()

  const [freeShippingMinPrice, setFreeShippingMinPrice] = useState(0)

  const [label, setLabel] = useState("")
  const [price, setPrice] = useState(0)

  const addShippingOption = (layout) => {
    const value = parseInt(layout.cart.shipping.shipping_options[layout.cart.shipping.shipping_options.length - 1].value) + 1;

    layout.cart.shipping.shipping_options.push({
      value: value,
      label: label,
      price: price
    })

    editLayoutRequest({ layoutData: JSON.stringify(layout) },
      (data) => {
        const layoutData = JSON.parse(data.layoutData);
        setLayout(layoutData)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const removeShippingOption = (layout, item) => {
    const index = layout.cart.shipping.shipping_options.indexOf(item);
    if (index > -1) {
      layout.cart.shipping.shipping_options.splice(index, 1);
    }

    editLayoutRequest({ layoutData: JSON.stringify(layout) },
      (data) => {
        const layoutData = JSON.parse(data.layoutData);
        setLayout(layoutData)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const canAdd = () => {
    return (label !== '')
  }

  const contextValue = {
    label,
    price,
    freeShippingMinPrice,

    canAdd,
    addShippingOption,
    removeShippingOption,

    setLabel,
    setPrice,
    setFreeShippingMinPrice
  }

  return (
    <ShippingSettingsContext.Provider value={contextValue}>
      {children}
    </ShippingSettingsContext.Provider>
  )
}

export const useShippingSettingsContext = () => useContext(ShippingSettingsContext)

export default ShippingSettingsProvider