import { Autocomplete, Button, Paper, Rating, Stack, Switch, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useProductsContext } from '../../../providers/ProductsProvider';
import SelectImageDialog from './SelectImageDialog';
import ShowSelectedImages from './ShowSelectedImages';
import { ProductsState, ProductSex } from '../../../defs'
import { useMenuContext } from '../../../providers/MenuProvider';
import { useLayoutContext } from '../../../providers/web_layout/WebLayoutProvider';


const ProductsEditor = ({ productsState }) => {

    const { contentML } = useMenuContext()
    const { layout } = useLayoutContext()

    const {
        setProductsState,
        title,
        setTitle,
        category,
        setCategory,
        price,
        setPrice,
        oldPrice,
        setOldPrice,
        tags,
        setTags,
        sex,
        setSex,
        shortDescription,
        setShortDescription,
        description,
        setDescription,
        stars,
        setStars,
        hidden,
        setHidden,

        canCreate,
        initWithEmptyData,
        createProductButtonHandler,
        editProductButtonHandler
    } = useProductsContext();

    useEffect(() => {
        if (productsState === ProductsState.ADD) {
            initWithEmptyData()
        }
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Box sx={{
            position: 'absolute',
            top: '60px',
            left: contentML,
            right: '0',
            overflowX: 'none'
        }}>
            <Stack spacing={2} mt={2} ml={2} mr={2}>
                <Paper sx={{ p: '20px' }}>
                    <Box>
                        <Typography sx={{ m: '10px' }}>Общие сведения</Typography>
                        <Stack spacing={2} >
                            <Stack direction='row' spacing={2}>
                                <TextField
                                    fullWidth
                                    required
                                    id="outlined-required"
                                    label="Название товара"
                                    value={title}
                                    onChange={(e) => { setTitle(e.target.value) }}
                                />
                                <Autocomplete
                                    fullWidth
                                    autoHighlight
                                    disableClearable
                                    value={category}
                                    options={layout.categories.categories_data}
                                    onChange={(event, newValue) => {
                                        setCategory(newValue)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Выберите категорию"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            </Stack>


                            <Stack direction='row' spacing={2}>
                                <TextField
                                    fullWidth
                                    required
                                    type='number'
                                    id="outlined-required"
                                    label="Цена"
                                    value={price}
                                    onChange={(e) => { setPrice(e.target.value) }}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    type='number'
                                    id="outlined-required"
                                    label="Старая цена"
                                    value={oldPrice}
                                    onChange={(e) => { setOldPrice(e.target.value) }}
                                />
                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <TextField
                                    fullWidth
                                    required
                                    id="outlined-required"
                                    label="Тэги(через запятую)"
                                    value={tags}
                                    onChange={(e) => { setTags(e.target.value) }}
                                />
                                <Autocomplete
                                    fullWidth
                                    autoHighlight
                                    disableClearable
                                    value={sex}
                                    options={ProductSex}
                                    onChange={(event, newValue) => {
                                        setSex(newValue)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Тип товара"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            <TextField
                                multiline
                                rows={5}
                                required
                                id="outlined-required"
                                label="Короткое описание"
                                value={shortDescription}
                                onChange={(e) => { setShortDescription(e.target.value) }}
                            />
                            <TextField
                                multiline
                                rows={5}
                                required
                                id="outlined-required"
                                label="Описание"
                                value={description}
                                onChange={(e) => { setDescription(e.target.value) }}
                            />
                        </Stack>
                    </Box>
                </Paper>
                <Paper sx={{ p: '20px' }}>
                    <Stack spacing={1}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Stack direction='column' justifyContent='center'>
                                <Typography>Рэйтинг</Typography>
                                <Typography sx={{ fontSize: '12px' }}>Чем выше значение тем выше будет приоритет при отображении</Typography>
                            </Stack>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Rating
                                    precision={0.5}
                                    value={stars}
                                    onChange={(e, newValue) => {
                                        setStars(newValue);
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Stack direction='column' justifyContent='center'>
                                <Typography>Скрыть продукт</Typography>
                                <Typography sx={{ fontSize: '12px' }}>Если не осталось на складе или просто надо скрыть из списка</Typography>
                            </Stack>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Switch checked={hidden} onChange={(e) => {
                                    setHidden(e.target.checked)
                                }} />
                            </Box>
                        </Box>
                    </Stack>
                </Paper>

                <SelectImageDialog />
                <ShowSelectedImages />
                <Stack spacing={2} direction='row' justifyContent='left' pb={5}>
                    <Button variant='contained' onClick={() => { setProductsState(ProductsState.LIST) }}>CANCEL</Button>

                    {
                        productsState === ProductsState.ADD ?
                            (
                                <Button variant='contained' disabled={!canCreate()} onClick={createProductButtonHandler}>CREATE</Button>
                            ) : (
                                <Button variant='contained' disabled={!canCreate()} onClick={(editProductButtonHandler)}>SAVE</Button>
                            )
                    }
                </Stack>
            </Stack>
        </Box>
    );
};

export default ProductsEditor;

