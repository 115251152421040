import React, { createContext, useContext, useState } from 'react'
import { useApiContext } from '../ApiProvider'
import { useLayoutContext } from './WebLayoutProvider'

const CategoriesContext = createContext()

const CategoriesSettingsProvider = ({ children }) => {

    const { editLayoutRequest } = useApiContext()
    const { setLayout } = useLayoutContext()

    const [name, setName] = useState("")
    const [icon, setIcon] = useState("")
    const [cover, setCover] = useState("")
    const [manCover, setManCover] = useState("")
    const [womanCover, setWomanCover] = useState("")
    const [hidden, setHidden] = useState(false)
    const [hasGenderFilter, setHasGenderFilter] = useState(false)

    const resetValues = () => {
        setName("")
        setIcon("/assets/images/categorys/glasses.png");
        setCover("/assets/images/page-title/glasses-cover-man.jpg")
        setManCover("/assets/images/page-title/glasses-cover-man.jpg")
        setWomanCover("/assets/images/page-title/glasses-cover-woman.jpg")
        setHidden(false)
        setHasGenderFilter(false);
    }

    const initValues = (categoryObject) => {
        setName(categoryObject.name)
        setIcon(categoryObject.icon);
        setCover(categoryObject.cover)
        setHidden(categoryObject.hidden)
        setHasGenderFilter(categoryObject.has_gender_filter);

        if (categoryObject.has_gender_filter) {
            setManCover(categoryObject.covers.man)
            setWomanCover(categoryObject.covers.woman)
        }
    }

    const addCategory = (layout) => {

        let covers = {}
        if (hasGenderFilter) {
            covers = {
                man: manCover,
                woman: womanCover
            }
        }

        const id = parseInt(layout.categories.categories_data[layout.categories.categories_data.length - 1].id) + 1;

        layout.categories.categories_data.push({
            id: id,
            name: name,
            icon: icon,
            cover: cover,
            has_gender_filter: hasGenderFilter,
            covers: covers,
            hidden: hidden,
            discount: [
                0,
                0,
                0,
                0
            ],
            link: "/category?id=" + id
        })

        editLayoutRequest({ layoutData: JSON.stringify(layout) },
            (data) => {
                const layoutData = JSON.parse(data.layoutData);
                setLayout(layoutData)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const updateCategory = (layout, item) => {
        const index = layout.categories.categories_data.indexOf(item);

        if (index > -1) {
            let covers = {}
            if (hasGenderFilter) {
                covers = {
                    man: manCover,
                    woman: womanCover
                }
            }

            item.name = name;
            item.icon = icon;
            item.cover = cover;
            item.has_gender_filter = hasGenderFilter;
            item.covers = covers;
            item.hidden = hidden;

            layout.categories.categories_data[index] = item;
        }

        editLayoutRequest({ layoutData: JSON.stringify(layout) },
            (data) => {
                const layoutData = JSON.parse(data.layoutData);
                setLayout(layoutData)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const removeCategory = (layout, item) => {
        const index = layout.categories.categories_data.indexOf(item);
        if (index > -1) {
            layout.categories.categories_data.splice(index, 1);
        }

        editLayoutRequest({ layoutData: JSON.stringify(layout) },
            (data) => {
                const layoutData = JSON.parse(data.layoutData);
                setLayout(layoutData)
            },
            (error) => {
                console.log(error)
            }
        )
    }


    const canSave = () => {

    }


    const canAdd = () => {
        let result = false;
        if (name !== '' && icon !== '' && cover !== '') {
            if (hasGenderFilter) {
                if (manCover !== '' && womanCover !== '') {
                    result = true
                }
            } else {
                result = true
            }
        }

        return result
    }


    const contextValue = {
        name,
        icon,
        cover,
        manCover,
        womanCover,
        hidden,
        hasGenderFilter,

        canAdd,
        canSave,
        resetValues,
        initValues,
        addCategory,
        updateCategory,
        removeCategory,

        setName,
        setIcon,
        setCover,
        setManCover,
        setWomanCover,
        setHidden,
        setHasGenderFilter
    }

    return (
        <CategoriesContext.Provider value={contextValue}>
            {children}
        </CategoriesContext.Provider>
    )
}

export const useCategoriesContext = () => useContext(CategoriesContext)

export default CategoriesSettingsProvider