import { Box, IconButton, Paper, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useProductsContext } from '../../../providers/ProductsProvider';
import { Close } from '@mui/icons-material';
import { PUBLIC_CDN_URL } from '../../../defs';

const ShowSelectedImages = () => {
    const { gallery, deleteFromGallery } = useProductsContext();

    return (
        <Box>
            <Paper sx={{
                p: '20px'

            }}>
                {
                    gallery.length > 0 ? (

                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'flex-start'
                        }}>
                            {
                                gallery.map((item, index) => (
                                    <Paper key={index}
                                        sx={{
                                            p: '10px',
                                            m: '10px',
                                            width: '10.8%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                        <Wrapper>
                                            <img alt='' src={PUBLIC_CDN_URL + "/static/images/" + item} width='100%' />
                                            <IconWrapper>
                                                <IconButton onClick={() => { deleteFromGallery(item) }}>
                                                    <Close sx={{ backgroundColor: '#cccccc', color: 'white' }} />
                                                </IconButton>
                                            </IconWrapper>
                                        </Wrapper>
                                    </Paper>
                                ))
                            }
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: gallery.length > 0 ? 'left' : 'center'
                            }}
                        >
                            <Typography sx={{ opacity: '0.5', userSelect: 'none' }}>Пока нету картинок</Typography>
                        </Box>
                    )
                }


            </Paper>
        </Box>
    );
};

export default ShowSelectedImages;


const Wrapper = styled.div`
  width: '100%';
  height: '100%';
  position: relative;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`