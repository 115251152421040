import React, { createContext, useContext, useEffect, useState } from 'react';
import { GalleryState, MaxGalleryItemsPerPage } from '../defs';
import { useApiContext } from './ApiProvider';

const GalleryContext = createContext()

const GalleryProvider = ({ children }) => {

    const [galleryState, setGalleryState] = useState(GalleryState.LIST);
    const [gallery, setGallery] = useState([])
    const [pagesCount, setPagesCount] = useState(0)
    const [page, setPage] = useState(1)
    const [fetching, setFetching] = useState(false)

    const { getGalleryRequest, uploadImageRequest, deleteImageRequest } = useApiContext()

    const deleteGalleryItem = (item) => {
        deleteImageRequest({ galleryItem: item, count: MaxGalleryItemsPerPage }, (data) => {
            setGallery(data.gallery)
            setPagesCount(data.totalCount)

            setPage(1)
        }, errorHandler);
    }

    const errorHandler = (data) => {
        console.log(data)
    }

    const uploadImage = (selectedImage, completeCallback, errorCalback) => {
        const formData = new FormData();
        formData.append("title", 'Upload file');
        formData.append("body", JSON.stringify({ count: MaxGalleryItemsPerPage }));
        formData.append("image", selectedImage)

        uploadImageRequest(formData,
            (data) => {
                setGallery(data.gallery)
                setPagesCount(data.totalCount)

                completeCallback()
            }, (error) => {
                errorCalback(error)
                errorHandler(error)
            })
    }

    const switchToPage = (pageIndex) => {
        setFetching(true);
        getGalleryRequest({ count: MaxGalleryItemsPerPage, pageIndex: pageIndex - 1 }, (data) => {
            setGallery(data.gallery)
            setPagesCount(data.totalCount)
            setFetching(false)
        }, errorHandler)
    }

    useEffect(() => {
        switchToPage(page)
    }, []);//eslint-disable-line react-hooks/exhaustive-deps


    const contextValue = {
        galleryState,
        gallery,
        pagesCount,
        fetching,
        page,

        setPage,
        setGallery,
        setGalleryState,
        setPagesCount,

        uploadImage,
        switchToPage,
        deleteGalleryItem
    }

    return (
        <GalleryContext.Provider value={contextValue}>
            {children}
        </GalleryContext.Provider>
    );
};

export const useGalleryContext = () => useContext(GalleryContext);

export default GalleryProvider;
